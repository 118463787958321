import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 42, width: 200, mr: 4 }}>
<svg xmlns="http://www.w3.org/2000/svg"
     width="4.76389in" height="0.986111in"
     viewBox="0 0 343 71">
  <path id="Selection"
        fill="gold" stroke="gray" strokeWidth="2"
        d="M 12.00,25.00
           C -1.27,22.80 10.93,5.41 11.50,5.02
             13.08,3.93 15.17,4.06 17.00,4.00
             17.00,4.00 12.00,20.00 12.00,20.00
             15.97,17.52 16.83,13.36 18.00,9.00
             18.00,9.00 28.00,9.00 28.00,9.00
             27.89,21.43 20.87,30.87 19.00,43.00
             19.00,43.00 24.00,42.00 24.00,42.00
             24.00,42.00 24.00,34.00 24.00,34.00
             24.00,34.00 28.00,34.00 28.00,34.00
             28.00,34.00 28.00,49.00 28.00,49.00
             28.00,49.00 13.00,47.98 13.00,47.98
             10.83,47.89 8.33,47.79 7.06,45.69
             5.18,42.56 11.02,29.30 12.00,25.00 Z
           M 31.00,4.00
           C 31.00,4.00 66.00,4.00 66.00,4.00
             66.00,4.00 66.00,9.00 66.00,9.00
             66.00,9.00 31.00,9.00 31.00,9.00
             31.00,9.00 31.00,4.00 31.00,4.00 Z
           M 105.00,4.00
           C 105.00,4.00 120.00,4.00 120.00,4.00
             120.00,4.00 120.00,7.00 120.00,7.00
             120.00,7.00 135.00,7.00 135.00,7.00
             135.00,7.00 135.00,11.00 135.00,11.00
             135.00,11.00 91.00,11.00 91.00,11.00
             91.00,11.00 91.00,7.00 91.00,7.00
             91.00,7.00 105.00,7.00 105.00,7.00
             105.00,7.00 105.00,4.00 105.00,4.00 Z
           M 142.00,16.00
           C 142.00,16.00 145.70,5.60 145.70,5.60
             145.70,5.60 154.00,4.00 154.00,4.00
             154.00,4.00 171.00,4.00 171.00,4.00
             170.56,5.36 170.56,6.28 169.41,7.40
             166.94,9.80 157.55,9.00 154.00,9.00
             154.00,9.00 153.00,16.00 153.00,16.00
             153.00,16.00 142.00,16.00 142.00,16.00 Z
           M 173.00,16.00
           C 173.00,16.00 176.51,5.60 176.51,5.60
             176.51,5.60 185.00,4.00 185.00,4.00
             185.00,4.00 202.00,4.00 202.00,4.00
             198.31,15.55 185.68,1.71 184.00,16.00
             184.00,16.00 173.00,16.00 173.00,16.00 Z
           M 214.00,4.00
           C 214.00,4.00 226.00,4.00 226.00,4.00
             227.29,8.04 227.86,8.31 232.00,9.00
             232.00,9.00 232.00,13.00 232.00,13.00
             232.00,13.00 208.00,13.00 208.00,13.00
             208.00,13.00 208.00,9.00 208.00,9.00
             208.00,9.00 214.00,9.00 214.00,9.00
             214.00,9.00 214.00,4.00 214.00,4.00 Z
           M 235.00,4.00
           C 235.00,4.00 258.00,4.00 258.00,4.00
             258.00,4.00 265.40,5.74 265.40,5.74
             265.40,5.74 267.00,13.00 267.00,13.00
             267.00,13.00 267.00,52.00 267.00,52.00
             267.00,52.00 269.00,66.00 269.00,66.00
             265.90,66.00 259.90,66.51 257.60,64.26
             255.95,62.65 256.04,60.13 256.00,58.00
             256.00,58.00 256.00,26.00 256.00,26.00
             256.00,22.49 256.79,13.00 254.40,10.60
             253.08,9.27 251.68,9.28 250.00,9.00
             250.00,9.00 250.00,27.00 250.00,27.00
             250.00,27.00 253.00,27.00 253.00,27.00
             253.00,27.00 253.00,31.00 253.00,31.00
             253.00,31.00 250.00,31.00 250.00,31.00
             250.00,31.00 250.00,58.00 250.00,58.00
             250.00,58.00 248.40,64.98 248.40,64.98
             248.40,64.98 237.00,66.00 237.00,66.00
             237.00,66.00 237.00,40.00 237.00,40.00
             237.00,40.00 234.00,27.00 234.00,27.00
             234.00,27.00 236.94,27.00 236.94,27.00
             236.94,27.00 236.94,13.04 236.94,13.04
             236.94,13.04 235.00,4.00 235.00,4.00 Z
           M 280.00,4.00
           C 280.00,4.00 287.00,4.00 287.00,4.00
             287.00,4.00 282.00,20.00 282.00,20.00
             282.00,20.00 288.51,10.02 288.51,10.02
             288.51,10.02 298.00,9.00 298.00,9.00
             298.00,9.00 289.00,43.00 289.00,43.00
             293.37,40.20 292.94,38.95 293.00,34.00
             293.00,34.00 297.00,34.00 297.00,34.00
             297.00,34.00 297.00,49.00 297.00,49.00
             293.70,48.73 279.23,48.09 277.61,46.40
             274.52,43.18 280.62,29.14 282.00,25.00
             270.65,23.14 277.64,14.33 280.00,4.00 Z
           M 300.00,65.00
           C 300.00,65.00 300.00,36.00 300.00,36.00
             300.00,36.00 300.00,12.00 300.00,12.00
             300.04,9.86 299.96,7.23 301.60,5.60
             303.52,3.68 307.46,4.01 310.00,4.00
             310.00,4.00 325.00,4.00 325.00,4.00
             327.26,4.00 331.62,3.75 333.43,5.17
             335.41,6.74 335.00,10.70 335.00,13.00
             335.00,13.00 335.00,58.00 335.00,58.00
             334.85,65.86 332.58,65.00 324.00,65.00
             324.00,65.00 326.00,53.00 326.00,53.00
             326.00,53.00 326.00,9.00 326.00,9.00
             326.00,9.00 310.00,9.00 310.00,9.00
             310.00,9.00 310.00,65.00 310.00,65.00
             310.00,65.00 300.00,65.00 300.00,65.00 Z
           M 75.00,5.00
           C 85.00,5.00 86.72,4.13 87.00,12.00
             87.00,12.00 75.00,12.00 75.00,12.00
             75.00,12.00 75.00,5.00 75.00,5.00 Z
           M 41.00,32.00
           C 38.96,31.99 35.24,32.24 33.58,30.98
             30.22,28.41 30.22,14.59 33.58,12.02
             35.33,10.70 39.80,11.00 42.00,11.00
             38.64,23.66 40.76,20.94 41.00,32.00 Z
           M 53.00,32.00
           C 50.96,31.99 47.24,32.24 45.58,30.98
             42.22,28.41 42.22,14.59 45.58,12.02
             47.33,10.70 51.80,11.00 54.00,11.00
             50.64,23.66 52.76,20.94 53.00,32.00 Z
           M 66.00,32.00
           C 63.83,32.00 59.30,32.30 57.66,30.98
             54.32,28.44 54.42,14.63 57.66,11.99
             59.39,10.68 63.88,11.00 66.00,11.00
             63.53,20.34 63.53,22.66 66.00,32.00 Z
           M 157.00,11.00
           C 164.33,11.00 167.53,9.60 169.00,16.00
             161.67,16.00 158.47,17.40 157.00,11.00 Z
           M 188.00,11.00
           C 188.00,11.00 199.00,11.00 199.00,11.00
             199.00,11.00 200.00,16.00 200.00,16.00
             192.67,16.00 189.47,17.40 188.00,11.00 Z
           M 102.00,13.00
           C 107.13,13.07 107.70,12.96 112.00,16.00
             115.57,12.48 117.24,13.02 122.00,13.00
             122.00,13.00 122.00,14.00 122.00,14.00
             122.00,14.00 120.00,16.00 120.00,16.00
             120.00,16.00 134.00,15.00 134.00,15.00
             134.00,15.00 134.00,19.00 134.00,19.00
             134.00,19.00 127.00,19.00 127.00,19.00
             127.00,19.00 128.00,32.00 128.00,32.00
             118.65,31.76 120.00,30.44 120.00,16.00
             114.80,22.20 116.00,24.31 116.00,32.00
             116.00,32.00 109.00,32.00 109.00,32.00
             109.00,32.00 110.00,17.00 110.00,17.00
             110.00,17.00 105.00,17.00 105.00,17.00
             105.00,30.47 106.22,31.76 97.00,32.00
             97.00,32.00 98.00,19.00 98.00,19.00
             98.00,19.00 91.00,19.00 91.00,19.00
             91.00,19.00 91.00,15.00 91.00,15.00
             91.00,15.00 103.00,15.00 103.00,15.00
             103.00,15.00 102.00,13.00 102.00,13.00 Z
           M 312.00,13.00
           C 312.00,13.00 316.00,13.00 316.00,13.00
             316.00,13.00 318.00,20.00 318.00,20.00
             318.27,15.38 318.13,13.84 323.00,13.00
             323.00,13.00 323.00,24.00 323.00,24.00
             323.00,24.00 312.00,24.00 312.00,24.00
             312.00,24.00 312.00,13.00 312.00,13.00 Z
           M 209.00,17.00
           C 209.00,17.00 231.00,17.00 231.00,17.00
             231.00,17.00 231.00,21.00 231.00,21.00
             231.00,21.00 209.00,21.00 209.00,21.00
             209.00,21.00 209.00,17.00 209.00,17.00 Z
           M 75.00,18.00
           C 85.01,18.00 86.29,17.12 88.00,25.00
             88.00,25.00 75.00,25.00 75.00,25.00
             75.00,25.00 75.00,18.00 75.00,18.00 Z
           M 143.00,65.00
           C 143.00,65.00 143.00,28.00 143.00,28.00
             143.00,25.81 142.79,22.16 144.02,20.31
             146.31,16.88 157.05,17.98 161.00,18.00
             163.33,18.01 167.40,17.75 169.01,19.74
             170.34,21.39 170.04,26.82 170.00,29.00
             169.97,30.34 169.99,32.48 169.40,33.69
             167.07,38.43 158.18,33.58 155.02,38.31
             153.88,40.02 154.01,43.00 154.00,45.00
             154.00,45.00 154.00,65.00 154.00,65.00
             154.00,65.00 143.00,65.00 143.00,65.00 Z
           M 188.00,65.00
           C 188.00,65.00 189.87,57.91 189.87,57.91
             189.87,57.91 189.87,45.00 189.87,45.00
             189.99,43.00 190.12,40.02 188.98,38.31
             186.21,34.16 178.64,37.39 175.60,34.98
             173.48,33.30 173.96,27.55 174.00,25.00
             174.14,17.06 175.81,17.89 192.00,18.00
             194.35,18.02 197.61,17.81 199.40,19.60
             201.19,21.39 200.98,24.65 201.00,27.00
             201.00,27.00 201.00,56.00 201.00,56.00
             201.00,58.30 201.38,62.41 199.40,63.98
             197.55,65.44 190.54,65.00 188.00,65.00 Z
           M 91.00,32.00
           C 91.00,26.33 89.53,22.14 96.00,21.00
             96.00,26.67 97.47,30.86 91.00,32.00 Z
           M 129.00,21.00
           C 129.00,21.00 134.00,21.00 134.00,21.00
             134.00,21.00 134.00,32.00 134.00,32.00
             127.70,30.91 129.00,26.30 129.00,21.00 Z
           M 160.00,23.00
           C 160.00,23.00 154.00,23.00 154.00,23.00
             154.00,23.00 154.00,25.00 154.00,25.00
             154.00,25.00 160.00,25.00 160.00,25.00
             160.00,25.00 160.00,23.00 160.00,23.00 Z
           M 190.00,23.00
           C 190.00,23.00 184.00,23.00 184.00,23.00
             184.00,23.00 184.00,25.00 184.00,25.00
             184.00,25.00 190.00,25.00 190.00,25.00
             190.00,25.00 190.00,23.00 190.00,23.00 Z
           M 209.00,25.00
           C 209.00,25.00 231.00,25.00 231.00,25.00
             231.00,25.00 231.00,30.00 231.00,30.00
             231.00,30.00 209.00,30.00 209.00,30.00
             209.00,30.00 209.00,25.00 209.00,25.00 Z
           M 314.00,27.00
           C 314.00,27.00 321.00,27.00 321.00,27.00
             323.83,38.92 320.81,32.87 321.00,45.00
             321.11,51.53 322.60,50.89 323.00,57.00
             323.00,57.00 314.60,55.40 314.60,55.40
             314.60,55.40 312.76,47.00 312.76,47.00
             312.76,47.00 312.76,32.28 312.76,32.28
             312.76,32.28 314.00,27.00 314.00,27.00 Z
           M 160.00,29.00
           C 160.00,29.00 154.00,29.00 154.00,29.00
             154.00,29.00 154.00,32.00 154.00,32.00
             154.00,32.00 160.00,32.00 160.00,32.00
             160.00,32.00 160.00,29.00 160.00,29.00 Z
           M 190.00,29.00
           C 190.00,29.00 184.00,29.00 184.00,29.00
             184.00,29.00 184.00,32.00 184.00,32.00
             184.00,32.00 190.00,32.00 190.00,32.00
             190.00,32.00 190.00,29.00 190.00,29.00 Z
           M 76.00,31.00
           C 76.00,31.00 87.00,31.00 87.00,31.00
             87.00,31.00 84.00,66.00 84.00,66.00
             84.00,66.00 73.00,66.00 73.00,66.00
             73.00,66.00 76.00,31.00 76.00,31.00 Z
           M 210.60,35.02
           C 213.71,33.70 228.66,32.82 230.83,35.57
             232.25,37.38 232.00,41.74 232.00,44.00
             232.01,47.98 232.98,60.82 230.40,63.40
             228.48,65.32 224.54,64.99 222.00,65.00
             219.47,65.01 212.44,65.43 210.60,63.98
             208.62,62.41 209.00,58.30 209.00,56.00
             208.99,50.81 207.79,38.93 210.60,35.02 Z
           M 32.00,35.00
           C 32.00,35.00 66.00,35.00 66.00,35.00
             66.00,35.00 66.00,40.00 66.00,40.00
             66.00,40.00 56.00,40.00 56.00,40.00
             56.00,40.00 56.00,61.00 56.00,61.00
             56.00,61.00 66.00,61.00 66.00,61.00
             66.00,61.00 66.00,65.00 66.00,65.00
             66.00,65.00 31.00,65.00 31.00,65.00
             31.00,65.00 31.00,61.00 31.00,61.00
             33.71,61.00 38.37,61.43 40.40,59.40
             42.74,57.06 42.74,43.94 40.40,41.60
             38.47,39.67 34.54,40.01 32.00,40.00
             32.00,40.00 32.00,35.00 32.00,35.00 Z
           M 92.00,35.00
           C 92.00,35.00 106.00,35.00 106.00,35.00
             106.00,35.00 106.00,38.00 106.00,38.00
             106.00,38.00 119.00,38.00 119.00,38.00
             119.00,38.00 119.00,35.00 119.00,35.00
             119.00,35.00 132.00,35.00 132.00,35.00
             132.00,35.00 132.00,65.00 132.00,65.00
             132.00,65.00 119.00,65.00 119.00,65.00
             119.00,65.00 119.00,51.00 119.00,51.00
             119.00,51.00 105.00,51.00 105.00,51.00
             105.00,51.00 104.00,65.00 104.00,65.00
             104.00,65.00 91.00,65.00 91.00,65.00
             91.00,65.00 92.00,35.00 92.00,35.00 Z
           M 222.00,38.00
           C 220.77,38.43 219.95,38.38 219.02,39.59
             217.76,41.24 218.00,44.96 218.00,47.00
             217.99,52.26 216.26,58.99 222.00,61.00
             222.00,61.00 222.00,38.00 222.00,38.00 Z
           M 161.29,39.74
           C 165.91,38.48 171.21,38.98 176.00,39.00
             178.33,39.01 182.40,38.75 184.01,40.74
             185.17,42.18 184.99,45.22 185.00,47.00
             185.02,50.07 185.56,61.40 183.98,63.40
             182.41,65.38 178.30,65.00 176.00,65.00
             173.01,65.01 162.59,65.56 160.60,63.98
             158.74,62.51 159.02,59.13 159.00,57.00
             158.97,52.24 157.55,42.87 161.29,39.74 Z
           M 119.00,41.00
           C 119.00,41.00 106.00,41.00 106.00,41.00
             106.00,41.00 105.00,47.00 105.00,47.00
             105.00,47.00 119.00,47.00 119.00,47.00
             119.00,47.00 119.00,41.00 119.00,41.00 Z
           M 175.00,44.00
           C 175.00,44.00 169.00,44.00 169.00,44.00
             170.05,49.95 173.95,49.95 175.00,44.00 Z
           M 6.00,51.00
           C 6.00,51.00 12.00,51.00 12.00,51.00
             12.00,51.00 12.00,65.00 12.00,65.00
             12.00,65.00 5.00,65.00 5.00,65.00
             5.00,65.00 6.00,51.00 6.00,51.00 Z
           M 14.00,51.00
           C 14.00,51.00 20.00,51.00 20.00,51.00
             20.00,51.00 20.00,65.00 20.00,65.00
             20.00,65.00 14.00,65.00 14.00,65.00
             14.00,65.00 14.00,51.00 14.00,51.00 Z
           M 22.00,51.00
           C 22.00,51.00 28.00,51.00 28.00,51.00
             28.00,51.00 29.00,65.00 29.00,65.00
             29.00,65.00 22.00,65.00 22.00,65.00
             22.00,65.00 22.00,51.00 22.00,51.00 Z
           M 175.00,52.00
           C 175.00,52.00 169.00,52.00 169.00,52.00
             169.00,52.00 169.00,61.00 169.00,61.00
             169.00,61.00 175.00,61.00 175.00,61.00
             175.00,61.00 175.00,52.00 175.00,52.00 Z
           M 276.00,51.00
           C 276.00,51.00 282.00,51.00 282.00,51.00
             282.00,51.00 281.00,65.00 281.00,65.00
             281.00,65.00 275.00,65.00 275.00,65.00
             275.00,65.00 276.00,51.00 276.00,51.00 Z
           M 283.00,51.00
           C 283.00,51.00 289.00,51.00 289.00,51.00
             289.00,51.00 289.00,65.00 289.00,65.00
             289.00,65.00 284.00,65.00 284.00,65.00
             284.00,65.00 283.00,51.00 283.00,51.00 Z
           M 291.00,51.00
           C 291.00,51.00 297.00,51.00 297.00,51.00
             297.00,51.00 297.00,65.00 297.00,65.00
             297.00,65.00 291.00,65.00 291.00,65.00
             291.00,65.00 291.00,51.00 291.00,51.00 Z" />
</svg>
    </SvgIcon>
  );
}